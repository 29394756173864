import React from "react"

import Container from "react-bootstrap/Container"

import Header from "../header"
import Footer from "../footer"


class Layout extends React.Component {
  render() {
    const { logo, children } = this.props;

    return (
      <Container fluid="true" className="site px-0 main">
        <Header
          borderColour="border-threesixty-orange"
          logo={logo}
          textColour="text-black"
        />
        <main className="site-content mt-5 mb-5">{children}</main>
        <Footer />
      </Container>
    )
  }
}

Layout.defaultProps = {
  logo: "logo_svg_blank_orange.svg"
}

export default Layout
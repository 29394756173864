import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts/investments_layout"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import * as investmentStyles from "./investment.module.css"

const Investment = ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout
      logo="logo_svg_investments.svg"
    >
      <Container>
        <Row>
          <Col>
            <div className={`investment ${investmentStyles.investment}`}>
              <h1>{post.frontmatter.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Investment;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
